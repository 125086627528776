import { ErrorMessage, Field } from "formik";
import { default as React } from "react";

export interface BaseInputFieldProps {
  inputName: string;
  labelText?: string;
  optional?: boolean;
  className?: string;
  inputType?: string;
}

export interface InputFieldProps extends BaseInputFieldProps {
  placeholder?: string | undefined;
  customError?: string;
}

const InputField = (props: InputFieldProps) => {
  const placeholder =
    (props.placeholder ?? "") + (props.optional ? " (optional)" : "");
  const inputType = props.inputType ?? "text";
  const inputID = "optional-" + props.inputName;

  return (
    <div className="tw-grid tw-grid-cols-1 tw-text-sm">
      <label htmlFor={props.inputName}>
        {props.labelText}
        {props.optional}
      </label>
      <Field
        id={props.inputName}
        name={props.inputName}
        placeholder={placeholder}
        type={inputType}
        className="tw-rounded-lg tw-p-2 tw-my-0 tw-border tw-border-gray-500 tw-shadow-sm focus:tw-border-indigo-500"
        aria-describedby={props.optional ? inputID : null}
      />
      <ErrorMessage
        component="p"
        className="tw-mt-2 tw-text-sm tw-text-red-600"
        name={props.inputName}
      />
      {props.customError && (
        <p className="tw-mt-2 tw-text-sm tw-text-red-600">
          {props.customError}
        </p>
      )}
    </div>
  );
};

export default InputField;
