// Needed to extend the windows object with our various trackers and keep TS quiet
// https://stackoverflow.com/a/12709880
declare global {
  interface Window {
    uetq: undefined | any[];
    fbq: undefined | any;
    gtag: undefined | any;
  }
}

const recordEvent = (identifier: string, label?: string) => {
  // Don't track field audits
  if (window.isFieldAudit) return;

  // Google Analytics
  if (window.gtag !== undefined) {
    if (label !== undefined) {
      window.gtag("event", identifier, { event_label: label });
    } else {
      window.gtag("event", identifier);
    }
  }

  // Facebook Pixel
  if (window.fbq !== undefined) {
    window.fbq("trackCustom", identifier);
  }
};

const quoteStarted = () => {
  recordEvent("Get A Quote");
};

const propertyTypePicked = (houseType: string) => {
  recordEvent("Property Type Picked", houseType);
};

const hasConservatory = (value: string) => {
  recordEvent("Has Conservatory", value);
};

const priceDisplayed = () => {
  recordEvent("Confirm Quote");
};

const contactPreferenceSelected = (source: string = "online") => {
  recordEvent("Contact Selection", source);
};

const contactDetailsEntered = (source: string = "online") => {
  recordEvent("Contact Selection", source);
};

const paymentMethod = (value: string) => {
  recordEvent("Payment Method", value);
};

const quoteSubmitted = (source: string) => {
  recordEvent("Quote Submit", source);
  // Bing tracking
  window.uetq = window.uetq || [];
  window.uetq.push("event", "page_view", {
    page_path: "/get-a-quote/thank-you",
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  quoteStarted,
  propertyTypePicked,
  hasConservatory,
  priceDisplayed,
  contactPreferenceSelected,
  contactDetailsEntered,
  quoteSubmitted,
  paymentMethod,
};
