import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { isValid, toNormalised } from "postcode";
import { postcodeAreaLookup } from "../utils/api";
import { IoLocation } from "react-icons/io5";
import Tracking from "../utils/Tracking";

const PostCodeEntry = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();
  const passedInPostcode = AppState.postcode;
  const [postcodeIsValid, setPostcodeIsValid] = useState(true);
  const [postcode, setPostcode] = useState(passedInPostcode);
  const [haveDoneAreaSearch, setHaveDoneAreaSearch] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const inputRef: React.MutableRefObject<null | HTMLInputElement> =
    useRef(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (postcodeIsValid && postcode.length > 0) {
        handleOnClick();
      }
    }
  };

  const handleOnClick = useCallback(() => {
    //console.info("handleOnClick called");

    Tracking.quoteStarted();

    if (postcodeIsValid && postcode.length > 0) {
      //console.info("going to do the search");
      AppState.setPostcode(postcode);
      AppState.setAreaInfo(null); // clear out any old data
      setSearchSubmitted(true);
      postcodeAreaLookup(postcode).then((data) => {
        data.json().then((results) => {
          setHaveDoneAreaSearch(true);
          setSearchSubmitted(false);

          console.info("Did search", results);

          if (results) {
            if (results.error && results.error === "No area found") {
              AppState.setAreaInfo(null);
            } else {
              AppState.setAreaInfo(results);
              navigateTo("/get-a-quote/step-1");
            }
          }
        });
      });
    }
  }, [AppState, navigateTo, postcode, postcodeIsValid]);

  useEffect(() => {
    // Make the input have focus straight away
    setTimeout(() => {
      console.info("Setting focus");
      if (inputRef.current && inputRef.current.focus) {
        inputRef.current.focus();
      }
    }, 500);
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.info("key press!");
    const value = event.currentTarget.value.toUpperCase();
    if (value.length > 3) {
      const postcodeCleaned = value.trim(); // We don't do a trim before hand as they might be typing the " " between the postcode parts
      if (isValid(postcodeCleaned)) {
        setPostcodeIsValid(true);
        const normalistedPostcode = toNormalised(postcodeCleaned);
        if (normalistedPostcode) {
          setPostcodeIsValid(true);
          setPostcode(normalistedPostcode);
        } else {
          setPostcodeIsValid(false);
          setPostcode(value);
        }
      } else {
        setPostcodeIsValid(false);
        setPostcode(value);
      }
    } else {
      setPostcodeIsValid(false);
      setPostcode(value);
    }
  };

  const handleReset = () => {
    /*    AppState.setAreaInfo(null);
    AppState.setPostcode("");
    setSearchSubmitted(false);
    setHaveDoneAreaSearch(false);
    setPostcode("");
    */
    window.location.href = "/get-a-quote";
  };

  const showError = !postcodeIsValid && postcode.length > 0;
  const showSorry = haveDoneAreaSearch && AppState.areaInfo === null;

  console.group();
  console.info("Show sorry", showSorry);
  console.info("Postcode", postcode);
  console.info("postcodeIsValid", postcodeIsValid);
  console.info("searchSubmitted", searchSubmitted);
  console.groupEnd();
  console.info("isValid(AppState.postcode)", isValid(AppState.postcode));
  console.info("AppState.isParsingQueryString", AppState.isParsingQueryString);
  console.info("Area Info", AppState.areaInfo);

  // Don't render the first step if we've been pass something on the querystring
  if (
    !showSorry &&
    ((postcode && postcodeIsValid && searchSubmitted) ||
      (isValid(AppState.postcode) && !AppState.areaInfo) ||
      AppState.isParsingQueryString)
  ) {
    return (
      <div className="tw-h-screen md:tw-h-auto tw-flex tw-justify-center tw-items-center tw-text-xl tw-pb-60">
        <div className="tw-flex tw-items-baseline">
          <IoLocation className="tw-animate-bounce tw-text-yellow-500" />
          <span className="tw-pl-2 tw-mb-2">Searching..</span>
        </div>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-h-screen md:tw-h-auto md:tw-pt-0">
      <div>
        {!showSorry && (
          <>
            {!AppState.isTechSignUp && (
              <h2 className="tw-text-xl tw-m-2 tw-text-center">
                Get a quote in 30 seconds
              </h2>
            )}
            <p className="tw-py-2 tw-text-center">
              Please enter your postcode below
            </p>
            <div className="tw-grid tw-grid-cols-1 tw-gap-2">
              <input
                type="text"
                onChange={handleOnChange}
                onKeyPress={handleKeyPress}
                value={postcode}
                className={
                  "tw-rounded-lg tw-p-4 tw-my-2 tw-border tw-border-gray-500 " +
                  (showError
                    ? " tw-ring tw-ring-red-300 tw-ring-3"
                    : " tw-shadow-sm")
                }
                placeholder="Postcode (AB12 3CD)"
                ref={inputRef}
              />
              {showError && (
                <span className="tw-text-sm tw-font-red-500">
                  Please enter a valid full postcode.
                </span>
              )}
              <div className="tw-flex tw-justify-end">
                {!searchSubmitted && (
                  <button
                    className={
                      "tw-rounded-lg tw-flex-1 tw-text-white tw-shadow-sm tw-px-12 tw-py-6 " +
                      (showError
                        ? " tw-cursor-not-allowed tw-bg-yellow-300"
                        : " tw-bg-yellow-500")
                    }
                    onClick={() => handleOnClick()}
                    disabled={showError}
                  >
                    GET STARTED
                  </button>
                )}
                {searchSubmitted && (
                  <span className="tw-bg-gray-100 tw-flex-1 tw-text-center tw-rounded-lg tw-text-gray-400 tw-shadow-sm tw-px-12 tw-py-6">
                    Searching...
                  </span>
                )}
              </div>
              <p className="tw-mt-4">
                You are only making an initial enquiry at this stage. If you are
                happy with your quote, you can then book your clean online or
                arrange for a call-back from one of our team
              </p>
            </div>
          </>
        )}
        {showSorry && (
          <div className="tw-grid tw-grid-cols-1 tw-items-center">
            <h2 className="tw-text-xl tw-m-2 tw-text-center">Sorry</h2>
            <p className="tw-py-2 tw-text-center">
              Preen &amp; Clean do not cover your area at present.{" "}
              <a href="/locations" className="tw-text-indigo-400">
                Locations we cover
              </a>
              .
            </p>
            <button
              className="tw-rounded-lg tw-bg-white tw-shadow-sm tw-px-12 tw-py-2 tw-border tw-border-gray-400"
              onClick={() => handleReset()}
              disabled={showError}
            >
              Search again
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostCodeEntry;
