import ActionButton from "../components/ActionButton";
import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ProgressBar, { Step } from "../components/ProgressBar";
import { IoCaretDown } from "react-icons/io5";
import Tracking from "../utils/Tracking";

/* How many bedrooms? */

const StepThree = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleOnClick = (value: string) => {
    const numOfBedrooms = parseInt(value, 10);
    AppState.setNumOfBedrooms(numOfBedrooms);
    if (numOfBedrooms < 6) {
      Tracking.priceDisplayed();
      navigateTo("/get-a-quote/step-4");
    } else {
      navigateTo("/get-a-quote/too-many-bedrooms");
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-h-screen md:tw-h-auto md:tw-pt-0">
      <ScrollToTopOnMount />
      <div className="tw-mb-auto">
        <p className="tw-py-2 tw-text-center">
          How many bedrooms does your property have?
        </p>
        <div className="tw-grid tw-grid-cols-2 tw-gap-2">
          <ActionButton
            text="1"
            value="1"
            handleOnClick={handleOnClick}
            isActive={false && AppState.numOfBedrooms === 1}
          />
          <ActionButton
            text="2"
            value="2"
            handleOnClick={handleOnClick}
            isActive={false && AppState.numOfBedrooms === 2}
          />{" "}
          <ActionButton
            text="3"
            value="3"
            handleOnClick={handleOnClick}
            isActive={false && AppState.numOfBedrooms === 3}
          />{" "}
          <ActionButton
            text="4"
            value="4"
            handleOnClick={handleOnClick}
            isActive={false && AppState.numOfBedrooms === 4}
          />{" "}
          <ActionButton
            text="5"
            value="5"
            handleOnClick={handleOnClick}
            isActive={false && AppState.numOfBedrooms === 5}
          />
          <ActionButton
            text="6+"
            value="6+"
            handleOnClick={handleOnClick}
            isActive={false && AppState.numOfBedrooms === 6}
          />
        </div>
      </div>
      <div className="stickyButtonBar">
        <div className="tw-flex tw-flex-1 tw-justify-between tw-items-center tw-p-4">
          <BackButton />
          <div className="tw-flex-0 tw-text-center">
            <ProgressBar
              steps={
                [
                  { name: "stepOne", status: "completed" },
                  { name: "stepTwo", status: "completed" },
                  { name: "stepThree", status: "current" },
                ] as Step[]
              }
              className="tw-w-max"
            />
            <button
              onClick={() => {
                const elem = window.document.getElementById("quote-function");
                if (elem) {
                  elem.setAttribute("tabindex", "-1");
                  elem.focus();
                  elem.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
              className="tw-text-sm tw-text-yellow-600 tw-w-40"
            >
              <span className="tw-flex tw-items-center tw-font-medium">
                Why Preen &amp; Clean?
                <IoCaretDown />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
