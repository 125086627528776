import React from "react";

interface ActionButtonProps {
  text: string;
  value: string;
  handleOnClick: Function;
  isActive: boolean;
}

const ActionButton = (props: ActionButtonProps) => {
  return (
    <button
      value={props.value}
      onClick={() => props.handleOnClick(props.value)}
      className={
        "tw-py-3 tw-px-6 tw-w-full tw-shadow-sm tw-block tw-border tw-border-gray-600 tw-rounded-md h-12" +
        (props.isActive ? " tw-bg-gray-100 tw-border-gray-300" : "")
      }
    >
      {props.text}
    </button>
  );
};

export default ActionButton;
