import ActionButton from "../components/ActionButton";
import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import { useEffect } from "react";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ProgressBar, { Step } from "../components/ProgressBar";
import { IoCaretDown } from "react-icons/io5";
import Tracking from "../utils/Tracking";

/* What sort of house do you have? */

const StepOne = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleOnClick = (value: string) => {
    Tracking.propertyTypePicked(value);
    AppState.setHouseTypeID(value);
    navigateTo("/get-a-quote/step-2");
  };

  useEffect(() => {
    // hide the welcome text
    const welcomeText = window.document.getElementById("welcomeTextForForm");
    if (welcomeText) {
      welcomeText.style.display = "none";
    }
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-h-screen md:tw-h-auto md:tw-pt-0">
      <ScrollToTopOnMount />
      <div className="tw-mb-auto">
        {!AppState.isTechSignUp && (
          <h2 className="tw-text-xl tw-m-2 tw-text-center">
            Great, we operate in your area!
          </h2>
        )}
        <p className="tw-py-2 tw-text-center">
          Please select {AppState.isTechSignUp ? "customers" : "your"} property
          type
        </p>
        <div className="tw-grid tw-grid-cols-2 tw-gap-2">
          {AppState.houseTypeOptions.map((option) => {
            return (
              <ActionButton
                text={option.text}
                value={option.value}
                handleOnClick={handleOnClick}
                isActive={false && AppState.houseTypeID === option.value}
                key={option.value}
              />
            );
          })}
        </div>
      </div>
      <div className="stickyButtonBar">
        <div className="tw-flex tw-flex-1 tw-justify-between tw-items-center tw-p-4">
          <BackButton />
          <div className="tw-flex-0 tw-text-center">
            <ProgressBar
              steps={
                [
                  { name: "stepOne", status: "current" },
                  { name: "stepTwo", status: "upcoming" },
                  { name: "stepThree", status: "upcoming" },
                ] as Step[]
              }
              className="tw-w-max"
            />
            <button
              onClick={() => {
                const elem = window.document.getElementById("quote-function");
                if (elem) {
                  elem.setAttribute("tabindex", "-1");
                  elem.focus();
                  elem.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
              className="tw-text-sm tw-text-yellow-600 tw-w-40"
            >
              <span className="tw-flex tw-items-center tw-font-medium">
                Why Preen &amp; Clean?
                <IoCaretDown />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
