import { Address } from "@ideal-postcodes/api-typings";
import React, { useReducer } from "react";

/** Generic model for drop downs, buttons and such */
export interface UserOption {
  text: string;
  value: string;
}

/**
 * The details of the service that the user has requested, this is what we will be firing back to the server if they sign up
 */
export interface RequestService {
  servicePricing: ServicePricing;
  frequencyID: string;
  /** The initial price for this service per visit */
  price: number;
  /** The text description of how we came up with this price, this is customer friendly and safe to show */
  priceBreakdown: string;
}

export interface ContactDetails {
  firstName: string;
  lastName: string;
  safeSpace?: string;
  email?: string;
  phoneNumber: string;
  address?: Address;
}

/**
 * Represents a service offered in this Area
 */
export interface ServicePricing {
  id: string;
  name: string;
  displayName: string;
  postcodesCovered?: (string | null)[];
  bedroomOneModifier?: number;
  bedroomTwoModifier?: number;
  bedroomThreeModifier?: number;
  bedroomFourModifier?: number;
  bedroomFiveModifier?: number;
  basePrice?: number;
  irregularFrequencyPercentageModifier?: number;
  conservatoryModifier?: number;
  discountPercentage?: number;
  discountAbsoluteExpiryDate?: string;
  discountRelativeExpiryMonths?: number;
  discountTotalCleansExpiry?: number;
  serviceID: string;
  regularFrequencyID: string;
}

/** Helper info about the Area that the users address falls within */
export interface AreaInfo {
  id: string;
  displayName: string;
  postcodesCovered: string[];
  servicePricing: ServicePricing[];
  isDiscounted?: boolean;
}

export interface StartUpDataInput {
  passedInPostCode?: string;
  existingPrice?: number;
  paymentMethodID?: string;
  contactDetails?: ContactDetails;
  additionalInfo?: string;
  areaInfo?: AreaInfo;
  existingRequestedServiceKey?: string;
  isFieldAudit?: boolean;
  preferredService?: string;
  techID?: string;
}

export interface StartUpDataClean {
  techID?: string;
  isTechSignUp?: boolean;
  postcode?: string;
  existingPrice?: number;
  paymentMethodID?: string;
  contactDetails?: ContactDetails;
  additionalInfo?: string;
  areaInfo?: AreaInfo;
  isFieldAudit?: boolean;
  preferredService?: AvailableServices;
  requestedServices?: Map<string, RequestService>;
  existingRequestedServiceKey?: string;
}

type AvailableServices =
  | "windows"
  | "gutters"
  | "soffits"
  | "conservatory"
  | undefined;

export interface AppState {
  isParsingQueryString: boolean;
  isFieldAudit: boolean;
  isTechSignUp: boolean;
  techID: string;
  instantClean: boolean;
  numOfBedrooms: number;
  hasConservatory?: boolean;
  postcode: string;
  houseTypeID: string;
  paymentMethodID: string;
  contactDetails: ContactDetails | null;
  additionalInfo: string;
  safeSpace: string;
  marketingOptIn: boolean;
  setInstantClean(value: boolean): void;
  setStartUpData(data: StartUpDataInput): void;
  setIsParsingQueryString(isParsingQueryString: boolean): void;
  setExistingPrice(existingPrice: number): void;
  setPreferredService(service: string): void;
  setNumOfBedrooms(bedrooms: number): void;
  setHasConservatory(toggle: boolean): void;
  setPostcode(postcode: string): void;
  setHouseTypeID(houseTypeID: string): void;
  setContactDetails(contactDetails: ContactDetails, isCallback: boolean): void;
  setPaymentMethod(paymentMethodID: string): void;
  setAreaInfo(areaInfo: AreaInfo | null): void;
  setMarketingOptIn(optedIn: boolean): void;
  setAdditionalInfo(notes: string): void;
  setSafeSpace(text: string): void;
  requestedServices: Map<string, RequestService>;
  addRequestedService(id: string, value: RequestService | null): void;
  removeRequestedService(id: string): void;
  houseTypeOptions: UserOption[];
  areaInfo?: AreaInfo;
  postcodeLookupAPIKey: string;
  submitDetailsToAPI: Function;
  isCallback: boolean;
  preferredService: AvailableServices;
  existingPrice: number; // If an audit then this is the price the customer is currently paying
  existingRequestedServiceKey: string; // Which service did the customer what to sign up for
}

const initialState: AppState = {
  isParsingQueryString: true,
  isFieldAudit: false,
  isTechSignUp: false,
  techID: "",
  instantClean: false,
  numOfBedrooms: 0,
  hasConservatory: undefined, // Initial we don't know the answer to this either way so we use undefined to show that its in an unknown state
  postcode: "",
  houseTypeID: "",
  paymentMethodID: "",
  contactDetails: null,
  additionalInfo: "",
  safeSpace: "",
  marketingOptIn: false,
  setInstantClean: () => {},
  setStartUpData: () => {},
  setIsParsingQueryString: () => {},
  setExistingPrice: () => {},
  setPreferredService: () => {},
  setNumOfBedrooms: () => {},
  setHasConservatory: () => {},
  setPostcode: () => {},
  setHouseTypeID: () => {},
  setContactDetails: () => {},
  setPaymentMethod: () => {},
  setAreaInfo: () => {},
  setMarketingOptIn: () => {},
  setAdditionalInfo: () => {},
  setSafeSpace: () => {},
  requestedServices: new Map<string, RequestService>(),
  addRequestedService: () => {},
  removeRequestedService: () => {},
  houseTypeOptions: [
    {
      text: "Semi-detached",
      value: "semi-detached",
    },
    {
      text: "Detached",
      value: "detached",
    },
    {
      text: "Terrace",
      value: "terrace",
    },
    {
      text: "Maisonette",
      value: "three-storey",
    },
    {
      text: "Flat",
      value: "flat",
    },
    {
      text: "Bungalow",
      value: "bungalow",
    },
  ],
  postcodeLookupAPIKey: "ak_kf5nnh8mih3T0PXT12s8QhV7dn8Qe",
  submitDetailsToAPI: () => {},
  isCallback: false,
  preferredService: undefined,
  existingPrice: 0,
  existingRequestedServiceKey: "",
};

type Action =
  | { type: "setInstantClean"; value: boolean }
  | { type: "setStartUpData"; data: StartUpDataClean }
  | { type: "setIsParsingQueryString"; value: boolean }
  | { type: "setNumOfBedrooms"; numOfBedrooms: number }
  | { type: "setExistingPrice"; existingPrice: number }
  | { type: "setAreaInfo"; areaInfo: AreaInfo }
  | { type: "setPostcode"; postcode: string }
  | { type: "setHasConservatory"; hasConservatory: boolean }
  | { type: "setHouseTypeID"; houseTypeID: string }
  | {
      type: "setContactDetails";
      contactDetails: ContactDetails;
      isCallback: boolean;
    }
  | { type: "setPaymentMethodID"; paymentMethodID: string }
  | { type: "setMarketingOptIn"; optedIn: boolean }
  | { type: "setAdditionalInfo"; text: string }
  | { type: "setSafeSpace"; text: string }
  | {
      type: "setRequestedService";
      requestedServices: Map<string, RequestService>;
    }
  | {
      type: "setPreferredService";
      service: AvailableServices;
    };

function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "setInstantClean": {
      return {
        ...state,
        instantClean: action.value,
      };
    }
    case "setStartUpData": {
      return {
        ...state,
        ...action.data,
        isParsingQueryString: false,
      };
    }

    case "setExistingPrice": {
      return { ...state, existingPrice: action.existingPrice };
    }

    case "setAreaInfo": {
      return {
        ...state,
        areaInfo: action.areaInfo,
        isParsingQueryString: false,
      };
    }

    case "setNumOfBedrooms": {
      return { ...state, numOfBedrooms: action.numOfBedrooms };
    }
    case "setPostcode": {
      return { ...state, postcode: action.postcode };
    }
    case "setHasConservatory": {
      return { ...state, hasConservatory: action.hasConservatory };
    }
    case "setHouseTypeID": {
      return { ...state, houseTypeID: action.houseTypeID };
    }
    case "setContactDetails": {
      //console.info("In resolver for contactdetails", action );
      return {
        ...state,
        contactDetails: action.contactDetails,
        isCallback: action.isCallback,
      };
    }
    case "setPaymentMethodID": {
      return { ...state, paymentMethodID: action.paymentMethodID };
    }
    case "setMarketingOptIn": {
      return { ...state, marketingOptIn: action.optedIn };
    }
    case "setAdditionalInfo": {
      return { ...state, additionalInfo: action.text };
    }
    case "setSafeSpace": {
      return { ...state, safeSpace: action.text };
    }
    case "setRequestedService": {
      return { ...state, requestedServices: action.requestedServices };
    }
    case "setPreferredService": {
      console.info("Dispatch", action.service);
      return { ...state, preferredService: action.service };
    }
    default: {
      console.error("ERROR:Unknown action passed to reducer", action);
      throw new Error("Unknown action passed to reducer");
    }
  }
}

export const AppStateContext = React.createContext(initialState);

AppStateContext.displayName = "AppState";

// See here for the odd typing: https://stackoverflow.com/a/58670190/107691
export const AppStateProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  state.setInstantClean = (value: boolean) => {
    dispatch({ type: "setInstantClean", value });
  };

  /** Used to record values from the form entered by the user */
  state.setAreaInfo = (areaInfo: AreaInfo) => {
    dispatch({ type: "setAreaInfo", areaInfo: areaInfo });
  };

  state.setExistingPrice = (price: number) => {
    dispatch({ type: "setExistingPrice", existingPrice: price });
  };
  state.setNumOfBedrooms = (bedrooms: number) => {
    dispatch({ type: "setNumOfBedrooms", numOfBedrooms: bedrooms });
  };

  state.setHasConservatory = (toggle: boolean) => {
    dispatch({ type: "setHasConservatory", hasConservatory: toggle });
  };

  state.setPostcode = (postcode: string) => {
    dispatch({ type: "setPostcode", postcode: postcode });
  };

  state.setHouseTypeID = (houseTypeID: string) => {
    dispatch({ type: "setHouseTypeID", houseTypeID: houseTypeID });
  };

  state.setContactDetails = (
    contactDetails: ContactDetails,
    isCallback: boolean
  ) => {
    //console.info("setting contact details", contactDetails );
    dispatch({
      type: "setContactDetails",
      contactDetails: contactDetails,
      isCallback: isCallback,
    });
  };

  state.setAdditionalInfo = (text: string) => {
    dispatch({ type: "setAdditionalInfo", text: text });
  };
  state.setSafeSpace = (text: string) => {
    dispatch({ type: "setSafeSpace", text: text });
  };

  state.setMarketingOptIn = (optedIn: boolean) => {
    dispatch({ type: "setMarketingOptIn", optedIn: optedIn });
  };

  state.setPaymentMethod = (paymentMethodID: string) => {
    dispatch({ type: "setPaymentMethodID", paymentMethodID: paymentMethodID });
  };

  state.submitDetailsToAPI = (
    contactDetails: ContactDetails,
    isCallback: boolean | undefined
  ): Promise<Response> | undefined => {
    console.group("submitDetailsToAPI");
    //console.info("Contact details passed in", contactDetails );

    // This stuff is a mess, in a rush at go live we had to work around some issue with Reacts state not updating in time.
    if (contactDetails === undefined && state.contactDetails !== null) {
      //console.info("Contact from state as none passed in", state.contactDetails );
      contactDetails = state.contactDetails;
    }

    if (isCallback === undefined) {
      isCallback = state.isCallback;
    }

    //console.info("API sending, Is Callback?", isCallback );

    let totalPriceOfServices = 0;
    let selectedServicesString = "";
    let priceBreakDownString = "";
    let servicePricingSummaries: {
      frequencyID: string;
      servicePricingID: string;
      quotedPrice: number;
      priceBreakdown: string;
      name: string;
    }[] = [];
    state.requestedServices.forEach((value, key, map) => {
      totalPriceOfServices = totalPriceOfServices + value.price;
      priceBreakDownString +=
        value.servicePricing.displayName + " - " + value.price + ". \n";
      selectedServicesString +=
        (selectedServicesString !== "" ? ", " : "") +
        value.servicePricing.displayName;
      servicePricingSummaries.push({
        frequencyID: value.frequencyID,
        servicePricingID: value.servicePricing.id,
        quotedPrice: value.price,
        priceBreakdown: value.priceBreakdown,
        name: value.servicePricing.displayName,
      });
    });

    var data = new FormData();

    //console.info("Contact details", contactDetails );

    if (contactDetails !== null) {
      // Legacy form stuff, we still send this for now as a backup
      data.append("action", "qbv_submission");
      data.append(
        "name",
        contactDetails.firstName +
          (contactDetails.firstName !== "" ? " " : "") +
          contactDetails?.lastName
      );
      data.append("phone", contactDetails.phoneNumber);
      data.append("addressLine1", contactDetails.address?.line_1 ?? "");
      data.append("email", contactDetails.email ?? "");
      data.append(
        "postcode",
        contactDetails.address?.postcode ?? state.postcode
      );
      data.append("options", selectedServicesString);
      data.append("totalPrice", totalPriceOfServices.toFixed(2));
      data.append("services", priceBreakDownString);
      data.append("paymentMethod", state.paymentMethodID);
      data.append("method", state.isFieldAudit ? "field-audit" : "webform");
      data.append("bedrooms", state.numOfBedrooms + "");
      data.append("houseType", state.houseTypeID);
      data.append("conservatory", state.hasConservatory + "");
      data.append("additionalInfo", state.additionalInfo);
      data.append("tandc", state.marketingOptIn + "");

      // Now we add our new fields that we can use in the system
      data.append("_isExtendedForm", "true");
      data.append("isFieldAudit", state.isFieldAudit ? "true" : "false");
      data.append("techID", state.techID);
      data.append("instantClean", state.instantClean ? "true" : "false");
      data.append("firstName", contactDetails.firstName);
      data.append("lastName", contactDetails.lastName);
      const addr = contactDetails.address;
      if (addr) {
        data.append("addressLine1", addr.line_1);
        data.append("addressLine2", addr.line_2);
        data.append("addressLine3", addr.line_3);
        data.append("town", addr.post_town);
        data.append("county", addr.county);
        data.append("longitude", addr.longitude + "");
        data.append("latitude", addr.latitude + "");
        data.append("buildNumber", addr.building_number);
        data.append("buildName", addr.building_name);
        data.append("premise", addr.premise);
        data.append("postcodeOutward", addr.postcode_outward);
        data.append("postcodeInward", addr.postcode_inward);
      }
      data.append("safeSpace", state.safeSpace ?? "");
      for (var i = 0; i < servicePricingSummaries.length; i++) {
        const service = servicePricingSummaries[i];
        const prefix = `service-${i}-`;
        data.append(prefix + "frequencyID", service.frequencyID);
        data.append(prefix + "priceBreakDown", service.priceBreakdown);
        data.append(
          prefix + "quotedPrice",
          service.quotedPrice.toFixed(2) + ""
        );
        data.append(prefix + "servicePricingID", service.servicePricingID);
        data.append(prefix + "name", service.name);
      }

      data.append("isCallback", isCallback ? "true" : "false");
    }
    console.groupEnd();

    return fetch("/wp-admin/admin-post.php", {
      method: "POST",
      body: data,
    });
  };

  state.removeRequestedService = (id: string) => {
    if (state.requestedServices.has(id)) {
      const requestedServicesUpdated = new Map(state.requestedServices);
      requestedServicesUpdated.delete(id);
      dispatch({
        type: "setRequestedService",
        requestedServices: requestedServicesUpdated,
      });
    }
  };

  const buildRequestedServicesMap = (id: string, value: RequestService) => {
    const requestedServicesUpdated = new Map(state.requestedServices);
    const fourWeekWindowID = "service-hotWindowClean-four-weekly";
    const eightWeekWindowID = "service-hotWindowClean-eight-weekly";
    // We can only have one or the other of these services
    if (
      id === fourWeekWindowID &&
      requestedServicesUpdated.has(eightWeekWindowID)
    ) {
      requestedServicesUpdated.delete(eightWeekWindowID);
    }
    if (
      id === eightWeekWindowID &&
      requestedServicesUpdated.has(fourWeekWindowID)
    ) {
      requestedServicesUpdated.delete(fourWeekWindowID);
    }

    requestedServicesUpdated.set(id, value);

    console.info("Build service map", requestedServicesUpdated);

    return requestedServicesUpdated;
  };

  state.addRequestedService = (id: string, value: RequestService) => {
    dispatch({
      type: "setRequestedService",
      requestedServices: buildRequestedServicesMap(id, value),
    });
    console.info("Added Service", value);
  };

  state.setStartUpData = (data: StartUpDataInput) => {
    console.info("setStartUpData", data);
    const cleanData: StartUpDataClean = {};

    // These are safe to pass to our dispatch regardless
    cleanData.additionalInfo = data.additionalInfo ?? "";
    cleanData.existingPrice = data.existingPrice ?? 0;
    cleanData.isFieldAudit = data.isFieldAudit ?? false;
    cleanData.paymentMethodID = data.paymentMethodID ?? "";
    cleanData.postcode = data.passedInPostCode ?? "";
    cleanData.techID = data.techID ?? "";
    cleanData.isTechSignUp = data.techID !== undefined && data.techID !== "";

    let preferredService: AvailableServices = "windows";
    switch (data.preferredService) {
      case "gutters":
        preferredService = "gutters";
        break;
      case "soffits":
        preferredService = "soffits";
        break;
      case "conservatory":
        preferredService = "conservatory";
        break;
    }
    cleanData.preferredService = preferredService;

    // All these should only be included if defined.
    if (data.areaInfo) {
      cleanData.areaInfo = data.areaInfo;
    }

    if (data.contactDetails) {
      cleanData.contactDetails = data.contactDetails;
    }

    if (data.existingRequestedServiceKey) {
      cleanData.existingRequestedServiceKey = data.existingRequestedServiceKey;
    }

    console.info("Start up data", cleanData);

    dispatch({
      type: "setStartUpData",
      data: cleanData,
    });
  };

  const values = React.useMemo(() => state, [state]) as any | null;

  return (
    <AppStateContext.Provider value={values}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = (): AppState => {
  const context = React.useContext(AppStateContext);

  if (context === undefined) {
    throw new Error(
      "`useAppState` hook must be used within a `AppStateProvider` component"
    );
  }
  return context;
};
