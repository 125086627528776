import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ContactDetailsForm, {
  ContactDetailsSubmissionValues,
} from "../components/ContactDetailsForm";
import Tracking from "../utils/Tracking";

/* New Customer contact details form */

const SignUpStep = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleSubmit = (values: ContactDetailsSubmissionValues) => {
    const isCallback = false;
    Tracking.contactDetailsEntered("online");
    AppState.setContactDetails(values, isCallback);
    navigateTo("/get-a-quote/payment-method");
  };

  return (
    <>
      <ScrollToTopOnMount />
      <ContactDetailsForm handleSubmit={handleSubmit} />
    </>
  );
};

export default SignUpStep;
