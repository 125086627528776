const BackButton = () => {
  return (
    <button
      className="tw-border tw-border-gray-500 tw-rounded-lg tw-shadow-sm tw-px-4 tw-py-2"
      onClick={() => window.history.back()}
    >
      « Back
    </button>
  );
};

export default BackButton;
