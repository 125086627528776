import { useAppState } from "../provider/AppStateProvider";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ContactDetailsForm, {
  ContactDetailsSubmissionValues,
} from "../components/ContactDetailsForm";
import { useNavigate } from "react-router";
import Tracking from "../utils/Tracking";

const CallbackForm = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleSubmit = (values: ContactDetailsSubmissionValues) => {
    const isCallback = true;
    Tracking.contactDetailsEntered("callback request");
    Tracking.quoteSubmitted("callback request");
    
    AppState.setContactDetails(values, isCallback );
    AppState.submitDetailsToAPI(values, isCallback)
      .then(() => {
        navigateTo("/get-a-quote/thank-you");
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <>
      <ScrollToTopOnMount />
      <div>
        <ContactDetailsForm handleSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default CallbackForm;
