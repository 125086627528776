import { Address } from "@ideal-postcodes/api-typings";
import { Client as PostcodeLookupClient } from "@ideal-postcodes/core-browser";
import * as Sentry from "@sentry/react";
import { ErrorMessage, Field, Form, FormikProps, withFormik } from "formik";
import React, { useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ContactDetails, useAppState } from "../provider/AppStateProvider";
import BackButton from "./BackButton";
import InputField from "./InputField";

interface FormProps {
  handleSubmit: Function;
  addressOptions: Address[];
  postcode: string;
  postcodeIsValid: boolean;
  intialContactDetails: ContactDetails | null;
}

export interface ContactDetailsSubmissionValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  address?: Address;
}

interface ContactDetailsFormInputValue {
  firstName: string;
  lastName: string;
  addressID: number;
  email: string;
  phoneNumber: string;
}

const ContactDetailsFormSchema = Yup.object({
  firstName: Yup.string().trim().required("Required"),
  lastName: Yup.string().trim().required("Required"),
  email: Yup.string().email(),
  phoneNumber: Yup.string().trim().min(11).max(16).required("Required"),
  addressID: Yup.number()
    .moreThan(-1, "Please select your address")
    .required("Please select your address"),
});

type State = {
  addressOptions: Address[];
  postcodeIsValid: boolean;
};

const initialState: State = {
  addressOptions: [],
  postcodeIsValid: true,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "gotAddresses":
      return {
        ...state,
        addressOptions: action.addresses,
        postcodeIsValid: true,
      };
    case "postcodeUnknown":
      return {
        ...state,
        postcodeIsValid: false,
      };
    default:
      throw new Error();
  }
}

type Action =
  | { type: "gotAddresses"; addresses: Address[] }
  | { type: "postcodeUnknown" };

const ContactDetailsForm = (props: {
  handleSubmit(values: ContactDetailsSubmissionValues): void;
}) => {
  const AppState = useAppState();

  const [{ addressOptions, postcodeIsValid }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const APIKey = AppState.postcodeLookupAPIKey;

  useEffect(() => {
    const postCodeLookup = new PostcodeLookupClient({
      api_key: APIKey,
      timeout: 29000,
    });

    const getAddressesByPostcode = async (postcode: string, maxAttempts: number = 3) => {
      let addresses: Address[] = [];

      for (let attenpts = 0; attenpts < maxAttempts; attenpts++) {
        try {
          addresses = await postCodeLookup.lookupPostcode({ postcode });

          // Request was successfull, break out of loop
          break;
        } catch (error) {
          if (
            error instanceof PostcodeLookupClient.errors.IdpcRequestFailedError
          ) {
            // IdpcRequestFailedError indicates a 402 response code
            // Possibly the key balance has been depleted
          }
          console.warn("Error in postcode lookup", error);
  
          Sentry.captureException(error);
          Sentry.captureMessage(
            "Error with postcode lookup for postcode [" + postcode + "]"
          );
        }
      }

      //console.info("Addresses for " + postcode, addresses);

      return addresses;
    };

    getAddressesByPostcode(AppState.postcode).then((addresses) => {
      if (addresses.length === 0) {
        dispatch({ type: "postcodeUnknown" });
      } else {
        dispatch({ type: "gotAddresses", addresses: addresses });
      }
    });
  }, [AppState.postcode, APIKey]);

  const handleSubmit = async (values: ContactDetailsFormInputValue) => {
    if (!values) return;

    const usersAddress = addressOptions.find((addr) => {
      return addr.udprn + "" === values.addressID + "";
    });

    props.handleSubmit({
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      address: usersAddress,
    });
  };

  return (
    <ContactDetailsFormWrapper
      handleSubmit={handleSubmit}
      addressOptions={addressOptions}
      postcode={AppState.postcode}
      postcodeIsValid={postcodeIsValid}
      intialContactDetails={AppState.contactDetails}
    />
  );
};

const ContactDetailsFormUI = (
  props: FormProps & FormikProps<ContactDetailsFormInputValue>
) => {
  const {
    errors,
    addressOptions,
    values,
    initialValues,
    postcode,
    isSubmitting,
    postcodeIsValid,
    handleChange,
  } = props;

  let formHasValues = false;
  if (values && initialValues !== values) {
    formHasValues = true;
  }

  let hasErrors = false;
  if (errors && Object.keys(errors).length) {
    console.warn("Form validation errors", errors);
    hasErrors = true;
  } else {
    //console.info("Form is now valid!");
  }

  return (
    <Form className="tw-flex tw-flex-col tw-justify-between tw-h-screen md:tw-h-auto md:tw-pt-0">
      {addressOptions.length === 0 && postcodeIsValid && <></>}
      {addressOptions.length === 0 && !postcodeIsValid && (
        <div className="tw-grid tw-grid-1 tw-items-center">
          <p className="tw-text-sm tw-p-2 tw-my-0">
            We can't find any addresses that match postcode <b>{postcode}</b>.
            Please check your postcode is entered correctly.
          </p>
          <a
            href="/get-a-quote/"
            className="tw-bg-yellow-500 tw-rounded-lg tw-text-white tw-shadow-sm tw-px-12 tw-py-2 tw-text-center"
          >
            Try Again
          </a>
        </div>
      )}
      {addressOptions.length > 0 && postcodeIsValid && (
        <>
          <div className="tw-mb-auto">
            <h2 className="tw-text-xl tw-m-2 tw-text-center">
              Contact Details
            </h2>
            <p className="tw-py-2 tw-text-center">
              Please enter your details below, we will use this to contact you
            </p>
            <div className="tw-grid tw-grid-cols-1 tw-gap-2">
              <InputField inputName="firstName" labelText="First Name" />
              <InputField inputName="lastName" labelText="Last Name" />
              <div className="tw-grid tw-grid-cols-1">
                <label className="tw-text-sm">
                  Select address for {postcode} (
                  <Link to="/get-a-quote" className="tw-text-indigo-400">
                    wrong postcode?
                  </Link>
                  ):
                </label>
                <div className="tw-text-sm">
                  <Field
                    as="select"
                    name="addressID"
                    id="addressID"
                    onChange={handleChange}
                    className="tw-w-2/3 sm:tw-w-full tw-text-sm tw-rounded-lg tw-p-2 tw-my-0 tw-border tw-border-gray-500 tw-shadow-sm"
                  >
                    <option value={-1} key="please-select">
                      Select your address
                    </option>
                    {addressOptions.map((address) => (
                      <option value={address.udprn} key={address.udprn}>
                        {address.line_1}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component="p"
                    className="tw-mt-2 tw-text-sm tw-text-red-600"
                    name="addressID"
                  />
                </div>
              </div>
              <InputField
                inputName="phoneNumber"
                labelText="Phone number (mobile preferred)"
              />
              <InputField
                inputName="email"
                optional
                inputType="email"
                labelText="Email"
              />
            </div>
          </div>
          <div className="stickyButtonBar">
            <div className="tw-text-center tw-flex tw-flex-1 tw-justify-between tw-items-center tw-p-4">
              <BackButton />
              {(hasErrors || !formHasValues) && (
                <span className="tw-bg-gray-100 tw-rounded-lg tw-text-gray-400 tw-shadow-sm tw-px-12 tw-py-2">
                  Next »
                </span>
              )}
              {!hasErrors && formHasValues && !isSubmitting && (
                <button
                  type="submit"
                  className="tw-bg-yellow-500 tw-rounded-lg tw-text-white tw-shadow-sm tw-px-12 tw-py-2"
                >
                  Next »
                </button>
              )}
              {isSubmitting && (
                <span className="tw-bg-gray-100 tw-rounded-lg tw-text-gray-400 tw-shadow-sm tw-px-12 tw-py-2">
                  Processing...
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

const ContactDetailsFormWrapper = withFormik<
  FormProps,
  ContactDetailsFormInputValue
>({
  mapPropsToValues: (props) => {
    return {
      firstName: props?.intialContactDetails?.firstName ?? "",
      lastName: props?.intialContactDetails?.lastName ?? "",
      addressID: -1,
      town: "",
      email: props?.intialContactDetails?.email ?? "",
      phoneNumber: props?.intialContactDetails?.phoneNumber ?? "",
    } as ContactDetailsFormInputValue;
  },
  validationSchema: ContactDetailsFormSchema,
  handleSubmit: (
    values: ContactDetailsFormInputValue,
    { props, ...actions }
  ) => {
    //console.info("form submitting", values);
    props.handleSubmit(values);
  },
})(ContactDetailsFormUI);

export default ContactDetailsForm;
