import { useNavigate } from "react-router";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import Tracking from "../utils/Tracking";

const StepFive = () => {
  const navigateTo = useNavigate();

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-h-screen md:tw-h-auto md:tw-pt-0">
      <ScrollToTopOnMount />
      <div className="tw-mb-auto">
        <h2 className="tw-text-xl tw-m-2 tw-text-center">Next Step</h2>
        <p className="tw-py-2 tw-text-center">
          Please select one of the options below you are interested in and
          select 'Next'
        </p>
        <div className="tw-grid tw-grid-cols-1 tw-gap-2">
          <div
            className="tw-flex tw-justify-between tw-shadow-sm tw-border tw-rounded-lg tw-border-gray-400 tw-p-4 tw-cursor-pointer"
            onClick={() => {
              Tracking.contactPreferenceSelected("online");
              navigateTo("/get-a-quote/sign-up")
            }}
          >
            <div className="tw-flex-0">
              <h3 className="tw-font-semibold tw-text-base">
                Book Your Clean Online
              </h3>
              <ul className="tw-list-disc tw-list-inside tw-text-xxs">
                <li>
                  A confirmation text will be sent with your approximate clean
                  date
                </li>
                <li>
                  A welcome text will be sent detailing our customer commitment
                </li>
                <li>A text will also be sent the day before we arrive</li>
              </ul>
            </div>
            <button className="tw-relative tw-w-8 tw-h-8 tw-flex tw-flex-none tw-items-center tw-justify-center tw-rounded-full">
              <span
                className="tw-h-2.5 tw-w-2.5 tw-bg-white tw-rounded-full  tw-ring tw-ring-gray-100 tw-ring-offset-8"
                aria-hidden="true"
              ></span>
            </button>
            <span className="tw-sr-only">Book Your Clean Online</span>
          </div>
        </div>
        <h2 className="tw-text-xl tw-text-center tw-p-4">OR</h2>
        <div className="tw-grid tw-grid-cols-1 tw-gap-2">
          <div
            className="tw-flex tw-justify-between tw-shadow-sm tw-border tw-rounded-lg tw-border-gray-400 tw-p-4 tw-cursor-pointer"
            onClick={() => {
              Tracking.contactPreferenceSelected("callback");
              navigateTo("/get-a-quote/callback");
            }}
          >
            <div className="tw-flex-1">
              <h3 className="tw-font-semibold tw-text-base">
                Speak to a member of our team
              </h3>
            </div>
            <button className="tw-relative tw-w-8 tw-h-8 tw-flex tw-flex-none tw-items-center tw-justify-center tw-rounded-full ">
              <span
                className="tw-h-2.5 tw-w-2.5 tw-bg-white tw-rounded-full tw-ring tw-ring-gray-100 tw-ring-offset-8"
                aria-hidden="true"
              ></span>
            </button>
            <span className="tw-sr-only">Please call me back</span>
          </div>
        </div>
      </div>
      <div className="stickyButtonBar">
        <div className="tw-flex tw-flex-1 tw-justify-start tw-items-center tw-p-4">
          <BackButton />
        </div>
      </div>
    </div>
  );
};

export default StepFive;
