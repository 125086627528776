import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import { useEffect, useState } from "react";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import { TiArrowLeft } from "react-icons/ti";
import Tracking from "../utils/Tracking";
import ActionButton from "../components/ActionButton";

const AdditionalNotes = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();
  const [optedIn, setOptedIn] = useState(false);
  const [notes, setNotes] = useState(AppState.additionalInfo);
  const [safeSpace, setSafeSpace] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [apiRequestSent, setAPIRequestSent] = useState(false);

  const handleOnClick = () => {
    setSubmitting(true);
    AppState.setAdditionalInfo(notes);
    AppState.setSafeSpace(safeSpace);
    AppState.setMarketingOptIn(optedIn);
  };

  useEffect(() => {
    if (submitting && !apiRequestSent) {
      setAPIRequestSent(true);
      AppState.submitDetailsToAPI()
        .then(() => {
          Tracking.quoteSubmitted(
            AppState.isCallback ? "tooManyBedrooms" : "online"
          );
          setAPIRequestSent(false);
          navigateTo("/get-a-quote/thank-you");
        })
        .catch((error: any) => {
          setAPIRequestSent(false);
          console.error(error);
        });
    }
  }, [submitting, apiRequestSent]);

  return (
    <div className="tw-flex tw-flex-col tw-justify-between md:tw-pt-0">
      <ScrollToTopOnMount />
      <div className="tw-mb-auto">
        <h2 className="tw-text-xl tw-m-2">Additional Notes</h2>
        <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-text-left tw-text-sm">
          <label className="tw-py-1">
            Please add any additional information or questions you may have.
            <textarea
              value={notes}
              placeholder="Additional notes (optional)"
              onChange={(e) => setNotes(e.currentTarget.value)}
              className="tw-border tw-border-gray-400 tw-transition tw-rounded-lg tw-shadow-md tw-p-2 tw-mt-2 tw-w-full tw-h-24"
            />
          </label>
          {AppState.paymentMethodID === "cash" && (
            <label className="tw-py-1">
              Where is your safe space to leave cash payments?
              <textarea
                value={safeSpace}
                onChange={(e) => setSafeSpace(e.currentTarget.value)}
                placeholder="Under the red plant pot. (optional)"
                className="block tw-border tw-border-gray-400 tw-transition tw-rounded-lg tw-shadow-md tw-p-2 tw-mt-2 tw-w-full tw-h-24"
              />
            </label>
          )}
          {AppState.isTechSignUp && (
            <div className="tw-bg-yellow-20 tw-rounded-md tw-p-2">
              <p className="tw-p-2">Clean this property now?</p>
              <div className="tw-flex tw-justify-between tw-space-x-4">
                <ActionButton
                  text="Yes"
                  handleOnClick={() => AppState.setInstantClean(true)}
                  isActive={AppState.instantClean === true}
                  value="true"
                ></ActionButton>
                <ActionButton
                  text="No"
                  handleOnClick={() => AppState.setInstantClean(false)}
                  isActive={AppState.instantClean === false}
                  value="true"
                ></ActionButton>
              </div>
            </div>
          )}
          <label className="tw-cursor-pointer tw-bg-yellow-200 tw-rounded-md tw-p-2">
            <input
              type="checkbox"
              checked={optedIn}
              onChange={() => setOptedIn(!optedIn)}
              className="tw-mr-2 tw-shadow"
            />
            <TiArrowLeft className="tw-text-xl tw-inline" /> I agree for Preen
            &amp; Clean to use text messaging to keep me informed of our
            cleaning dates and to answer any questions. I'm happy for Preen
            &amp; Clean to store my details to help them provide this service.
          </label>
          <p>
            If you wish to see our commitment to your data privacy and terms and
            conditions please{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              className="tw-text-indigo-400"
            >
              read our privacy policy
            </a>
            .
          </p>
        </div>
      </div>
      <div className="tw-flex tw-flex-0 tw-justifiy-betwee md:tw-pb-2 tw-pt-2">
        <div className="tw-text-center tw-flex tw-flex-1 tw-justify-between tw-p-4">
          <BackButton />
          {!submitting && (
            <>
              {optedIn && (
                <button
                  className="tw-bg-yellow-500 tw-rounded-lg tw-text-white tw-shadow-sm tw-px-12 tw-py-2"
                  onClick={() => handleOnClick()}
                >
                  Next »
                </button>
              )}
              {!optedIn && (
                <span className="tw-bg-gray-100 tw-rounded-lg tw-text-gray-500 tw-shadow-sm tw-px-12 tw-py-2">
                  Next »
                </span>
              )}
            </>
          )}
          {submitting && (
            <span className="tw-bg-gray-100 tw-rounded-lg tw-text-gray-500 tw-shadow-sm tw-px-12 tw-py-2">
              Processing...
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalNotes;
