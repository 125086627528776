import { useEffect } from "react";

const ScrollToTopOnMount = () => {
  const location = window.location.href;

  useEffect(() => {
    //console.info("Scrolling as href changed", location);
    if (window.screen.availWidth <= 768) {
      window.scrollTo(0, 105); // Allow some space for the main menu
    }
  }, [location]);

  return null;
};

export default ScrollToTopOnMount;
