import { useNavigate } from "react-router";
import {
  RequestService,
  ServicePricing,
  useAppState,
} from "../provider/AppStateProvider";
import BackButton from "../components/BackButton";
import {
  getPriceBreakdown,
  PriceBreakdown,
  PriceOverrides,
} from "../utils/servicePricingHelpers";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ProgressBar, { Step } from "../components/ProgressBar";
import { IoCaretDown } from "react-icons/io5";
import { useState } from "react";

const StepFour = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();
  const [existingPriceConfirmed, setExistingPriceConfirmed] = useState(
    !AppState.isFieldAudit
  ); // Only need to worry about this if its a Field Audit
  const [editablePrice, setEditablePrice] = useState(
    AppState.existingPrice + ""
  );

  const DEFAULT_DISCOUNT_DURATION_IN_MONTHS = 3;

  interface ServiceMeta {
    serviceID: string;
    displayName: string;
    price: number;
    priceStr: string;
    frequencyID: string;
    marketingInfo: string[];
    conservatoryOnly: boolean;
  }

  const windowServices: ServiceMeta[] = [
    {
      serviceID: "service-hotWindowClean",
      displayName: "Window Clean every 4 weeks",
      price: 13.6, // These prices get overridden, only here for design purposes
      priceStr: "£13.60",
      frequencyID: "four-weekly",
      marketingInfo: [
        "No contract, free to leave any time",
        "Includes cleaning of all windows, frames, sills and doors",
        "We text you the day before we arrive",
      ],
      conservatoryOnly: false,
    },
    {
      serviceID: "service-hotWindowClean",
      displayName: "Window Clean every 8 weeks",
      price: 13.6, // These prices get overridden, only here for design purposes
      priceStr: "£13.60",
      frequencyID: "eight-weekly",
      marketingInfo: [
        "No contract, free to leave any time",
        "Includes cleaning of all windows, frames, sills and doors",
        "We text you the day before we arrive",
      ],
      conservatoryOnly: false,
    },
  ];
  const gutterServices: ServiceMeta[] = [
    {
      serviceID: "service-gutterClean",
      displayName: "Internal Gutter Clear",
      price: 80.0, // These prices get overridden, only here for design purposes
      priceStr: "£80.00",
      frequencyID: "one-off",
      marketingInfo: [
        "We plan for all gutter clears to be completed within 10 days",
        "Over 5000 satisfied customers",
        "Remove the clutter from your gutter, no mess!",
        "State of the art 40ft high powered vacuum technology",
        "We use our own generators so no need to use your electricity",
        "Fully insured, fixed price, pictures provided",
      ],
      conservatoryOnly: false,
    },
    {
      serviceID: "service-soffitClean",
      displayName: "External Gutter & Fascia Clean",
      price: 80.0, // These prices get overridden, only here for design purposes
      priceStr: "£80.00",
      frequencyID: "one-off",
      marketingInfo: [
        "Hot water external clean for an extra sparkle",
        "Special attached brushes for a deeper clean",
        "One off price",
        "Fully insured, fixed price",
      ],
      conservatoryOnly: false,
    },
  ];
  const conservatoryServices: ServiceMeta[] = [
    {
      serviceID: "service-conservatoryClean",
      displayName: "Conservatory Roof Clean",
      price: 60.0, // These prices get overridden, only here for design purposes
      priceStr: "£60.00",
      frequencyID: "one-off",
      marketingInfo: [
        "Hot water external clean for an extra sparkle",
        "Special attached brushes and UPVC treatment for a deeper clean",
        "One off price",
        "Fully insured, fixed price",
      ],
      conservatoryOnly: true,
    },
  ];

  let services: ServiceMeta[] = [];

  // This is a bit tricky if we ever add in another service but it works, stitch together the services in the order that the user has asked for them/shown an interest
  if (AppState.isFieldAudit) {
    // In an Audit on the door step we don't want to upsell just confirm what they signed up for
    services = windowServices;
  } else {
    switch (AppState.preferredService) {
      case "gutters": {
        services = gutterServices.concat(windowServices, conservatoryServices);
        break;
      }
      case "soffits": {
        services = gutterServices
          .reverse()
          .concat(windowServices, conservatoryServices);
        break;
      }
      case "conservatory": {
        services = conservatoryServices.concat(windowServices, gutterServices);
        break;
      }
      default: {
        services = windowServices.concat(gutterServices, conservatoryServices);
      }
    }
  }

  const handleExistingPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.info("Quoted price changed, local state");
    const newPriceRaw = event.currentTarget.value.trim();
    setEditablePrice(newPriceRaw);
  };

  const updateRequestedService = (newPrice: number) => {
    // This should only be done on FieldAudits so should be safe to assume we are just messing with the one and only service.
    console.info("Trying to update Requested Service");
    if (newPrice > -1) {
      console.info("Updating Requested Service");
      const firstEntry = AppState.requestedServices.entries().next().value;
      const key: string = firstEntry[0];
      const value: RequestService = firstEntry[1];

      AppState.removeRequestedService(key);
      AppState.addRequestedService(
        key,
        generateRequestedService(
          value.frequencyID,
          value.servicePricing,
          newPrice
        )
      );
    }
  };

  const generateRequestedService = (
    frequencyID: string | undefined,
    servicePricing: ServicePricing | null,
    existingPrice: number | null
  ): RequestService | null => {
    console.info("Generating a Requested Service", servicePricing);
    if (frequencyID !== undefined && servicePricing !== null) {
      let overrides: PriceOverrides | null = null;

      if (AppState.isFieldAudit) {
        overrides = {
          fixedPrice: AppState.existingPrice,
          discountRelativeExpiryMonths: DEFAULT_DISCOUNT_DURATION_IN_MONTHS,
        };
      }

      const priceBreakdown: PriceBreakdown = getPriceBreakdown(
        servicePricing,
        AppState.numOfBedrooms,
        frequencyID,
        AppState.hasConservatory ?? false,
        overrides
      );
      return {
        servicePricing: servicePricing,
        frequencyID: frequencyID,
        price: priceBreakdown.discountedTotalToPay || priceBreakdown.totalToPay,
        priceBreakdown:
          (AppState.isFieldAudit
            ? "Agreed historic price of £" +
              existingPrice +
              ", will honor for {DEFAULT_DISCOUNT_DURATION_IN_MONTHS} months from sign on date. "
            : "") + priceBreakdown.calculationDetails,
      };
    }

    return null;
  };

  const handleServiceClick = (
    serviceKey: string,
    service: ServiceMeta | null,
    servicePricing: ServicePricing | null
  ) => {
    if (AppState.requestedServices.has(serviceKey)) {
      AppState.removeRequestedService(serviceKey);
    } else {
      AppState.addRequestedService(
        serviceKey,
        generateRequestedService(service?.frequencyID, servicePricing, null)
      );
    }
  };

  const handleFormSubmit = () => {
    updateRequestedService(AppState.existingPrice);
    navigateTo(
      AppState.isFieldAudit || AppState.isTechSignUp
        ? "/get-a-quote/sign-up"
        : "/get-a-quote/step-5"
    );
  };

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-h-auto md:tw-pt-0">
      <ScrollToTopOnMount />
      <div className="tw-mb-auto">
        {!AppState.isFieldAudit && (
          <>
            {!AppState.isTechSignUp && (
              <>
                <h2 className="tw-text-lg tw-m-2 tw-text-center">
                  Your quote is ready!
                </h2>
                <p className="py-4 text-center">
                  Please select which service(s) you require below and then
                  click NEXT to book online or arrange a call back from one of
                  our team.
                </p>
              </>
            )}
            {AppState.isTechSignUp && (
              <>
                <h2 className="tw-text-lg tw-m-2 tw-text-center">
                  Available services
                </h2>
                <p className="py-4 text-center">
                  Select which service the customer requires.
                </p>
              </>
            )}
          </>
        )}
        {AppState.isFieldAudit && (
          <>
            <h2 className="tw-text-lg tw-m-2 tw-text-center">
              Confirm service and price
            </h2>
            <p>
              <span className="tw-font-bold">Expected service:</span>{" "}
              {AppState.existingRequestedServiceKey}
            </p>
            <p className="py-4 text-center">
              <label className="tw-my-6 tw-block">
                <span className="tw-font-bold">Existing Price: £</span>
                {!existingPriceConfirmed && (
                  <input
                    type="text"
                    value={editablePrice}
                    onChange={handleExistingPriceChange}
                    className="tw-border tw-border-gray-500 tw-rounded-md tw-ml-1 tw-w-2/12"
                  />
                )}
                {existingPriceConfirmed && (
                  <span>{AppState.existingPrice}</span>
                )}
              </label>
              {!existingPriceConfirmed && (
                <button
                  onClick={() => {
                    setExistingPriceConfirmed(true);
                    AppState.setExistingPrice(Number(editablePrice));
                  }}
                  className="tw-bg-yellow-500 tw-rounded-lg tw-text-white tw-shadow-sm tw-px-12 tw-py-2 pc-bold-text"
                >
                  Confirm
                </button>
              )}
            </p>
          </>
        )}
        {existingPriceConfirmed && (
          <div className="tw-grid tw-grid-cols-1 tw-gap-2">
            {services.map((service) => {
              /// GUARD

              if (service.serviceID === undefined) return null;

              // If we don't have a conservatory then don't render conservatory stuff
              if (service.conservatoryOnly && !AppState.hasConservatory)
                return null;

              // Have we had a service pricing returned for this service?
              const matchingServicePricings =
                AppState.areaInfo?.servicePricing.filter((sp) => {
                  return sp.serviceID === service.serviceID;
                });
              let servicePricing: ServicePricing | null = null;
              if (matchingServicePricings && matchingServicePricings[0]) {
                servicePricing = matchingServicePricings[0];
              }

              // Don't render it
              if (servicePricing === null) return null;

              const priceBreakdown: PriceBreakdown = getPriceBreakdown(
                servicePricing,
                AppState.numOfBedrooms,
                service.frequencyID,
                AppState.hasConservatory ?? false
              );

              console.info("Price breakdown", priceBreakdown);
              const serviceKey = service.serviceID + "-" + service.frequencyID;

              // ACTION
              return (
                <div
                  key={service.displayName}
                  className={
                    "tw-flex tw-justify-between tw-shadow-sm tw-border tw-cursor-pointer tw-rounded-lg tw-border-gray-400 tw-p-4 " +
                    (AppState.requestedServices.has(serviceKey)
                      ? " tw-bg-gray-100"
                      : "")
                  }
                  onClick={() =>
                    handleServiceClick(serviceKey, service, servicePricing)
                  }
                >
                  <div className="tw-flex-1">
                    <h3 className="tw-font-semibold tw-text-sm">
                      {service.displayName}{" "}
                      {!AppState.isFieldAudit && (
                        <span className="tw-text-yellow-500">
                          £
                          {(
                            priceBreakdown.discountedTotalToPay ||
                            priceBreakdown.totalToPay
                          ).toFixed(2)}
                        </span>
                      )}
                    </h3>
                    {service.marketingInfo && !AppState.isFieldAudit && (
                      <ul className="tw-list-disc tw-list-inside tw-text-xxs">
                        {priceBreakdown.hasDiscount && (
                          <li key="discount">
                            Price includes a {priceBreakdown.discountPercentage}
                            % introductory discount{" "}
                            {service.frequencyID !== "one-off" && (
                              <>
                                for the first{" "}
                                {priceBreakdown.discountDurationInMonths} months
                              </>
                            )}
                          </li>
                        )}

                        {service.marketingInfo.map((info) => {
                          return <li key={info}>{info}</li>;
                        })}
                      </ul>
                    )}
                  </div>
                  {!AppState.requestedServices.has(serviceKey) && (
                    <button className="tw-relative tw-w-8 tw-h-8 tw-flex tw-flex-none tw-items-center tw-justify-center tw-rounded-full">
                      <span
                        className="tw-h-2.5 tw-w-2.5 tw-bg-white tw-rounded-full tw-ring tw-ring-gray-100 tw-ring-offset-8"
                        aria-hidden="true"
                      ></span>
                    </button>
                  )}
                  {AppState.requestedServices.has(serviceKey) && (
                    <button className="tw-relative tw-w-8 tw-h-8 tw-flex tw-flex-none tw-items-center tw-bg-yellow-500 tw-justify-center tw-rounded-full">
                      <svg
                        className="tw-w-5 tw-h-5 tw-text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        {/* Heroicon name: solid/check */}
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )}
                  <span className="tw-sr-only">
                    Select {service.displayName}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="stickyButtonBar tw-border-0 tw-border-t-1 tw-border-gray-500">
        <div className="tw-flex tw-flex-1 tw-justify-between tw-items-center tw-p-4">
          <BackButton />
          <div className="tw-flex-0 tw-text-center">
            {AppState.requestedServices.size > 0 && (
              <button
                className="tw-bg-yellow-500 tw-rounded-lg tw-text-white tw-shadow-sm tw-px-12 tw-py-2 pc-bold-text"
                onClick={handleFormSubmit}
              >
                Next »
              </button>
            )}

            {AppState.requestedServices.size === 0 && (
              <>
                <ProgressBar
                  steps={
                    [
                      { name: "stepOne", status: "completed" },
                      { name: "stepTwo", status: "completed" },
                      { name: "stepThree", status: "completed" },
                    ] as Step[]
                  }
                  className="tw-w-max"
                />
                <button
                  onClick={() => {
                    const elem =
                      window.document.getElementById("quote-function");
                    if (elem) {
                      elem.setAttribute("tabindex", "-1");
                      elem.focus();
                      elem.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="tw-text-sm tw-text-yellow-600 tw-w-40 md:tw-hidden"
                >
                  <span className="tw-flex tw-items-center tw-font-medium">
                    Why Preen &amp; Clean?
                    <IoCaretDown />
                  </span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
