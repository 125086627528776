import ActionButton from "../components/ActionButton";
import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import Tracking from "../utils/Tracking";

const PaymentMethod = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleOnClick = (value: string) => {
    Tracking.paymentMethod(value);
    AppState.setPaymentMethod(value);

    navigateTo("/get-a-quote/additional-notes");
  };

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-max-w-screen md:tw-max-w-lg tw-min-h-screen md:tw-h-auto md:tw-min-h-0 md:tw-pt-0">
      <ScrollToTopOnMount />
      <div className="tw-mb-auto">
        <h2 className="tw-text-xl tw-m-2 tw-text-center">Payment Method</h2>
        <p className="tw-py-2 tw-text-center">
          Please tell us how{" "}
          {AppState.isTechSignUp ? "the customer would" : "you'd"} like to pay
          for your cleaning service?
        </p>
        <div className="tw-grid tw-grid-cols-1 tw-gap-2">
          <ActionButton
            text="Bank Transfer"
            value="bankTransfer"
            handleOnClick={handleOnClick}
            isActive={AppState.paymentMethodID === "bankTransfer"}
          />
          <ActionButton
            text="Cash (pay on the day or leave in a safe place)"
            value="cash"
            handleOnClick={handleOnClick}
            isActive={AppState.paymentMethodID === "cash"}
          />
          <ActionButton
            text="GoCardless Direct debit"
            value="directDebit"
            handleOnClick={handleOnClick}
            isActive={AppState.paymentMethodID === "directDebit"}
          />
        </div>
      </div>
      <div className="stickyButtonBar">
        <div className="tw-flex tw-flex-1 tw-justify-between tw-items-center tw-p-4">
          <BackButton />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
