/* eslint-disable no-lone-blocks */
import { Link } from "react-router-dom";
import React from "react";

export interface Step {
  name: string;
  url?: string;
  status?: "completed" | "current" | "upcoming";
}

export interface ProgressBarProps {
  steps: Step[];
  className?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
  if (props.steps.length === 0) return null;
  return (
    <nav
      aria-label="Progress"
      className={props.className + "  tw-flex tw-px-4"}
    >
      <ol className="tw-flex">
        {props.steps.map((step, index) => {
          return (
            <li
              className={
                "tw-relative" +
                (index === props.steps.length - 1
                  ? ""
                  : " tw-pr-16 sm:tw-pr-24")
              }
              key={step.name + step.status + step.url + index}
            >
              {/* Completed Step */}
              {step.status === "completed" && (
                <>
                  <div
                    className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                    aria-hidden="true"
                  >
                    <div className="tw-h-0.5 tw-w-full tw-bg-yellow-600"></div>
                  </div>
                  <Link
                    to={step.url ?? ""}
                    className="tw-relative tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-bg-yellow-500 tw-rounded-full hover:tw-bg-yellow-600"
                  >
                    {/* Heroicon name: solid/check */}
                    <svg
                      className="tw-w-5 tw-h-5 tw-text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="tw-sr-only">{step.name}</span>
                  </Link>
                </>
              )}
              {/* Current Step */}
              {step.status === "current" && (
                <>
                  <div
                    className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                    aria-hidden="true"
                  >
                    <div className="tw-h-0.5 tw-w-full tw-bg-yellow-400"></div>
                  </div>
                  <div
                    className="tw-relative tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-bg-white tw-border-2 tw-border-yellow-400 tw-rounded-full"
                    aria-current="step"
                  >
                    <span
                      className="tw-h-2.5 tw-w-2.5 tw-bg-yellow-400 tw-rounded-full"
                      aria-hidden="true"
                    ></span>
                    <span className="tw-sr-only">{step.name}</span>
                  </div>
                </>
              )}
              {/* Upcoming Step */}
              {step.status === undefined ||
                (step.status === "upcoming" && (
                  <>
                    <div
                      className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                      aria-hidden="true"
                    >
                      <div className="tw-h-0.5 tw-w-full tw-bg-yellow-400"></div>
                    </div>
                    <div className="tw-group tw-relative tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-bg-white tw-border-2 tw-border-yellow-400 tw-rounded-full hover:tw-border-yellow-600">
                      <span
                        className="tw-h-2.5 tw-w-2.5 tw-bg-transparent tw-rounded-full group-hover:tw-bg-yellow-400"
                        aria-hidden="true"
                      ></span>
                      <span className="tw-sr-only">{step.name}</span>
                    </div>
                  </>
                ))}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default ProgressBar;
