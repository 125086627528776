import { useAppState } from "../provider/AppStateProvider";
import ScrollToTopOnMount from "../utils/ScrollHelpers";

const StepFive = () => {
  const AppState = useAppState();
  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-h-screen md:tw-h-auto md:tw-pt-0">
      <ScrollToTopOnMount />
      <div className="tw-mb-auto tw-text-center">
        <div className="tw-flex tw-items-center tw-justify-center">
          <span className="tw-relative tw-w-8 tw-h-8 tw-flex tw-flex-none tw-items-center tw-bg-yellow-500 tw-justify-center tw-rounded-full">
            <svg
              className="w-5 h-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              {/* Heroicon name: solid/check */}
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <h2 className="tw-text-xl tw-m-2">You're all done!</h2>
        {AppState.isTechSignUp && (
          <>
            <p className="tw-text-sm tw-py-2">Customer has been added.</p>
            {AppState.instantClean && (
              <p className="tw-text-sm tw-py-2">
                A visit is being generated for this clean now within TAPP, you
                can start the clean now and reload TAPP in a few minutes to see
                it in your round.
              </p>
            )}
            {!AppState.instantClean && (
              <p className="tw-text-sm tw-py-2">
                A CAM will get this customer into the Round at the next
                available time.
              </p>
            )}
          </>
        )}
        {!AppState.isTechSignUp && (
          <>
            <p className="tw-text-sm tw-py-2">Thank you for your enquiry.</p>
            <p className="tw-text-sm tw-py-2">
              One of the team will be in touch the next working day.
            </p>
            <p className="tw-text-sm tw-py-2 tw-pb-6">
              Our normal office hours are Monday to Friday, 9.00am - 5.00pm
              excluding bank holidays.
            </p>
          </>
        )}
      </div>
      <div className="tw-flex tw-flex-0 tw-justifiy-center tw-items-center tw-text-center tw-h-15 tw-pb-60 md:tw-h-auto md:tw-pb-2 tw-pt-2">
        <div className="tw-flex tw-flex-1 tw-justify-center">
          {AppState.isTechSignUp && (
            <button
              className="tw-bg-yellow-500 tw-rounded-lg tw-text-white tw-shadow-sm tw-px-12 tw-py-2"
              onClick={() =>
                (window.location.href = "https://admin.preenandclean.com/tapp/")
              }
            >
              Back to TAPP
            </button>
          )}
          {!AppState.isTechSignUp && (
            <button
              className="tw-bg-yellow-500 tw-rounded-lg tw-text-white tw-shadow-sm tw-px-12 tw-py-2"
              onClick={() => (window.location.href = "/")}
            >
              Back to homepage
            </button>
          )}
        </div>
      </div>
      <div className="tw-block sm:tw-hidden"></div>
    </div>
  );
};

export default StepFive;
