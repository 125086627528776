import ActionButton from "../components/ActionButton";
import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ProgressBar, { Step } from "../components/ProgressBar";
import { IoCaretDown } from "react-icons/io5";
import Tracking from "../utils/Tracking";

/* Does it have a Conservatory? */

const StepTwo = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();
  const handleOnClick = (value: string) => {
    Tracking.hasConservatory(value);
    AppState.setHasConservatory(value === "true");
    navigateTo("/get-a-quote/step-3");
  };

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-h-screen md:tw-h-auto md:tw-pt-0">
      <ScrollToTopOnMount />
      <div className="tw-mb-auto">
        <p className="tw-py-2 tw-text-center">
          Has {AppState.isTechSignUp ? "the" : "your"} property got a
          conversatory?
        </p>
        <div className="tw-grid tw-grid-cols-1 tw-gap-2">
          <ActionButton
            text="Yes"
            value="true"
            handleOnClick={handleOnClick}
            isActive={
              false &&
              AppState.hasConservatory !== undefined &&
              AppState.hasConservatory
            }
          />
          <ActionButton
            text="No"
            value="false"
            handleOnClick={handleOnClick}
            isActive={
              false &&
              AppState.hasConservatory !== undefined &&
              !AppState.hasConservatory
            }
          />
        </div>
      </div>
      <div className="stickyButtonBar">
        <div className="tw-flex tw-flex-1 tw-justify-between tw-items-center tw-p-4">
          <BackButton />
          <div className="tw-flex-0 tw-text-center">
            <ProgressBar
              steps={
                [
                  { name: "stepOne", status: "completed" },
                  { name: "stepTwo", status: "current" },
                  { name: "stepThree", status: "upcoming" },
                ] as Step[]
              }
              className="tw-w-max"
            />
            <button
              onClick={() => {
                const elem = window.document.getElementById("quote-function");
                if (elem) {
                  elem.setAttribute("tabindex", "-1");
                  elem.focus();
                  elem.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
              className="tw-text-sm tw-text-yellow-600 tw-w-40"
            >
              <span className="tw-flex tw-items-center tw-font-medium">
                Why Preen &amp; Clean?
                <IoCaretDown />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
