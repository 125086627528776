import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ContactDetailsForm, {
  ContactDetailsSubmissionValues,
} from "../components/ContactDetailsForm";
import Tracking from "../utils/Tracking";

const TooManyBedrooms = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleSubmit = (values: ContactDetailsSubmissionValues) => {
    const isCallback = true;
    Tracking.contactDetailsEntered("too many bedrooms");
    AppState.setContactDetails(values, isCallback);
    navigateTo("/get-a-quote/payment-method");
  };

  return (
    <>
      <ScrollToTopOnMount />
      <h2 className="tw-text-xl tw-m-2 tw-text-center">
        Whoah that's a lot of bedrooms!
      </h2>
      <p className="tw-py-2 tw-text-center">
        Please give us a call on 0800 0614216 for a bespoke quote or fill in the
        form below and we will be in touch.
      </p>
      <div>
        <ContactDetailsForm handleSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default TooManyBedrooms;
